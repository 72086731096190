import * as React from "react";
import {  Link } from "gatsby-plugin-intl";

import Diamond from "../assets/images/svg/diamond.svg";

const ServiceBlock = ({ className = '', poster, title, subtitle = null, slug = '' }) => {
  return (
    <Link 
      className={`tro-service-teaser transition-all duration-500 ease-customTeaserPadding ${className}`}
      to={`/services/${slug}`}
    >
      <div className="relative">
        <Diamond className="absolute z-10 w-6 h-6 tro-diamond bottom-3 right-3 tablet:bottom-4 tablet:right-4 tablet:w-8 tablet:h-8"
        />

        {poster && (
          <img src={poster} alt="" loading="lazy"></img>
        )}
      </div>

      <div className="py-3 pr-6 tro-insight-content-default transition-all duration-500 ease-customTeaserPadding">
        <h2 className="pb-3 font-sans text-lg font-light small:text-xl medium:text-2xl">
          {title}
        </h2>
        {subtitle && (
          <p className="font-sans text-2xs small:text-xs medium:text-sm">
            {subtitle}
          </p>
        )}
      </div>
    </Link>
  );
};

export default ServiceBlock;
