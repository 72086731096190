import * as React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";

import Layout from "../layouts/default-layout";

import PageHead from "../components/pageHead";
import ServiceBlock from "../components/serviceBlock";

import { extractImageFromData } from '../lib/helpers';

const ServicesPage = ({ path, data }) => {
  const intl = useIntl()
  const content = data.sanityServices
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.subline ? content.subline[intl.locale] : null;  
  
  return (
    <Layout textWhite={content.lightHeader} title={metaTitle} description={metaDescription} noIndex={content.meta_noindex} path={path}>
      <PageHead
        headline={title}
        subline={content.subline && content.subline[intl.locale]}
        backgroundImage={extractImageFromData(content, 'poster')}
        textWhite={content.lightHeader}
      />

      <div className="pb-10 medium:pb-24 tro-default-padding-x">
        <div className="tro-grid-responsive small:gap-y-4 medium:gap-y-8 space-y-7 small:space-y-0">
          {content.services.map((item, index) => (
            <ServiceBlock
              key={index}
              className="col-span-full small:col-span-3 medium:col-span-6 large:col-span-4"
              poster={extractImageFromData(item, 'poster')}
              title={item.title[intl.locale]}
              subtitle={item.subtitle ? item.subtitle[intl.locale] : null}
              slug={item.singlepage?.slug[intl.locale]?.current}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ServicesPage;

export const query = graphql`
  query services {
    allSanityCase(sort: {order: DESC, fields: _createdAt}) {
      nodes {
        ... Case
      }
    }
    sanityServices(_id: {eq: "services"}) {
      title {
        de
        en
      }
      subline {
        de
        en
      }
      meta_title {
        de
        en
      }
      meta_description {
        de
        en
      }
      meta_noindex
      lightHeader
      poster {
        asset {
          url
          gatsbyImageData
        }
      }
      services {
        poster {
          asset {
            url
            gatsbyImageData
          }
        }
        title {
          de
          en
        }
        subtitle {
          de
          en
        }
        singlepage {
          slug {
            de {
              current
            }
            en {
              current
            }
          }
        }
      }
    }
  }
`
